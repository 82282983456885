import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import FooterNavigator from '../components/FooterNavigator';
import ProcessCard from '../components/ProcessCard';
import { useConfig } from '../ConfigContext';
import TitleHeader from '../components/TitleHeader';
import {google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';
import Loader from '../components/Loader';


import { useTranslation } from 'react-i18next';

import defaultProcessImage from '../img/default_images/foodchain_processing.png';
import noProcessImage from '../img/no_process.png';



const ProcessesPage = ({qr_code_hash, data}) => {

  const { t } = useTranslation();
  const config = useConfig();

  var the_processes = null;

  const metadata = useMetadata();

  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, [data]);


  if(data && data.processings){
    the_processes = data.processings.map((p)=>(p.foodchain_processing));
  }
  if(data && (the_processes === null || typeof(the_processes)==='undefined' || the_processes.length===0)){
    the_processes = data.template.map((p)=>(p.foodchain_template));
  }

  const globals = document.getElementById('globals');
  var is_there_supplychain_dom = globals.getAttribute('is_there_supplychain');
  var is_there_supplychain = is_there_supplychain_dom==='true';

  if(!data){
    return(
      <PageContainer>
        <Loader metadata={metadata} />
        <FooterNavigator data={data} qr_code_hash={qr_code_hash} selected_page={3}  is_there_supplychain={is_there_supplychain} />

      </PageContainer>
    )
  }



  if( the_processes && the_processes.length === 0){

    return (
    <PageContainer>

          <div className="process-list-container">
            <img className="no-process-img" src={noProcessImage} alt="no processes" />
            <h2 className="central-text">{t('no_process_title')}</h2>
            <p className="central-text">
              {t('no_process_text')}
            </p>
          </div>
      <FooterNavigator data={data} qr_code_hash={qr_code_hash} selected_page={3}  is_there_supplychain={is_there_supplychain}/>
    </PageContainer>
    )
  }

  return (
    <PageContainer>

      <TitleHeader title={t('processi')}/>


      <div className="page-body page-body-noheader">


        { the_processes && the_processes.length>0 && (
          <div className="process-list-container">
            {the_processes && the_processes.map((item, index) => (
              <ProcessCard
                qr_code_hash={qr_code_hash}
                key={index}
                imageUrl={item.images && item.images.length>0 ? (config.DOMAIN +  item.images[0].img_low_cropped) : defaultProcessImage}
                index={index}
                date={item.creation_date}
                title={item.name}
                description={item.description}
              />
            ))}
          </div>
        )}


      </div>
      <FooterNavigator data={data} qr_code_hash={qr_code_hash} selected_page={3}  is_there_supplychain={is_there_supplychain}/>
    </PageContainer>
  );
};

export default ProcessesPage;
