import React, { useRef, useState, useEffect, useCallback } from 'react';
import SupplyChainItem from './SupplyChainItem'; // Adjust the import path as needed
import '../styles.css';

const SupplyChainScroller = ({ items, callback, title, index }) => {

  const scrollerRef = useRef(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(index); // Track selected item



  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const onMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollerRef.current.offsetLeft;
    scrollLeft.current = scrollerRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - scrollerRef.current.offsetLeft;
    const walk = (x - startX.current) * 2; // Adjust scrolling speed as needed
    scrollerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  /*
  const handleItemClick = useCallback((index, item) => {
    setSelectedItemIndex(parseInt(index,10)); // Update selected item
    callback(parseInt(index,10), item); // Trigger the callback if needed
    console.log("THE CLICK");
  }, []); // Add any dependencies that 'handleItemClick' relies on
  */


  const handleItemClick = useCallback((index, item) => {
    setSelectedItemIndex(parseInt(index, 10));
    callback(parseInt(index, 10), item);

    if (scrollerRef.current) {
      const scroller = scrollerRef.current;
      const selectedElement = scroller.children[0].children[index]; // Adjust this if structure changes

      if (selectedElement) {
        const scrollerRect = scroller.getBoundingClientRect();
        const itemRect = selectedElement.getBoundingClientRect();

        const scrollerCenter = scrollerRect.width / 2;
        const itemCenter = itemRect.left - scrollerRect.left + itemRect.width / 2;

        const scrollOffset = itemCenter - scrollerCenter;
        scroller.scrollTo({
          left: scroller.scrollLeft + scrollOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [callback]);



  useEffect(() => {
      if(!window.location.href.includes("supplychain")) return; //prevents weird bug
      handleItemClick(index);

  },[handleItemClick, index])


  if(items===null || typeof items === 'undefined' || items.length === 0){
     return <div></div>;
  }

  return (

    <div>
        <h2 >

        {title}

        </h2>

        <div
          className="supply-chain-scroller"
          ref={scrollerRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <div className="scroller-inner">
            {items.map((item, the_index) =>
            (
              <SupplyChainItem
                key={the_index}
                imageUrl={item.imageUrl}
                label={item.label}
                onClick={() => handleItemClick(the_index, item)}
                isSelected={parseInt(selectedItemIndex) === parseInt(the_index)}
              />
            )

            )}
          </div>
        </div>
    </div>

  );
};

export default SupplyChainScroller;
