import React, { useState, useEffect } from 'react';
import BatchBox from './BatchBox';

const BatchList = ({ batches, selectedProduct }) => {
  const [selectedBatchId, setSelectedBatchId] = useState(null);

  const handleSelect = (batchId) => {
    setSelectedBatchId(batchId); // Updates selected batch
  };

  useEffect(() => {
    setSelectedBatchId(null);
  },[selectedProduct])


  return (
    <div className="batch-list">
      {batches.map((batch) => (
        <BatchBox
          key={batch.foodchain_batch.internal_number}
          batch={batch}
          isSelected={selectedBatchId === batch.foodchain_batch.internal_number}
          onSelect={() => handleSelect(batch.foodchain_batch.internal_number)}
        />
      ))}
    </div>
  );
};

export default BatchList;
