import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Define the maximum length for the initial text display
const LENGTH_FOR_TRUNCATE = 621;
const LENGTH_OF_TRUNCATE = 200;


const ExpandableText = ({ title, text }) => {

  const { t } = useTranslation();
  // State to track whether the full text is shown
  // Determine if the text is longer than MAX_LENGTH
  const isTextLong = text.length > LENGTH_FOR_TRUNCATE;
  const [isExpanded, setIsExpanded] = useState(!isTextLong);

  // Function to handle the button click
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <h2 className="gtrans">{title}</h2>
      {/* Display truncated or full text based on the state */}
      <p className="gtrans">
        {isExpanded ? text : text.substring(0, LENGTH_OF_TRUNCATE) + (isTextLong ? '...' : '')}
      </p>

      {/* Show "Read More" button if the text is long and not expanded */}
      {isTextLong && !isExpanded && (
        <div className="big-button-container">
          <button className="big-button gtrans" onClick={handleToggle}>{t('read_more')}</button>
        </div>
      )}

    </div>
  );
};

export default ExpandableText;
