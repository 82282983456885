import React, { useState, useEffect, useCallback } from 'react';
import PageContainer from '../components/PageContainer';
import FooterNavigator from '../components/FooterNavigator';
import SupplyChainScroller from '../components/SupplyChainScroller';
import TitleHeader from '../components/TitleHeader';
import { useConfig } from '../ConfigContext';
import MapRoute from '../components/MapRoute';
import Loader from '../components/Loader';
import BatchList from '../components/BatchList';
import {execute_RTL, google_translate_all, products_to_supply_chain_data} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';

import defaultProductImage from '../img/default_images/foodchain_products.png';

import { useTranslation } from 'react-i18next';

const SupplyChainPage = ({ qr_code_hash, data }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [itemIndex, setItemIndex] = useState(0);

  const [supplyChainItems, setSupplyChainItems] = useState([]);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productLots, setProductLots] = useState([]);
  const [mapPositions, setMapPositions] = useState([]);
  const [productUrl, setProductUrl] = useState([]);


  const metadata = useMetadata();

  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, [data]);



  const displaySupplyChainItem = useCallback((index) => {
    if(data && data.products && data.batch){


      if(supplyChainItems && supplyChainItems[index] && supplyChainItems[index]){


        var the_p = supplyChainItems[index];
        setProductName(the_p.label);
        setProductDescription(the_p.description);

        setProductUrl([config.URL_DOMAIN + the_p.item_id.substring(2)]);

        var candidates = the_p.batches;

        if(candidates && candidates.length > 0){
          var product_lot_numbers = [];
          var product_urls = [];

          candidates.forEach((the_batch)=>{
            product_lot_numbers.push(the_batch.foodchain_batch.internal_number);
            product_urls.push(config.URL_DOMAIN + the_batch.foodchain_batch.item_id.substring(2));
          });
          setProductLots([...candidates]);
          setProductUrl([...product_urls]);

          console.log("productLotNumber !!!!!!!!!!!!!!!!!!");
          console.log(product_lot_numbers);
        }
      }
    };

    execute_RTL();
  }, [config.URL_DOMAIN, data, supplyChainItems]); // Add any dependencies that 'handleItemClick' relies on




  const selectSupplyChainItem = (index) => {
    //console.log("selectSupplyChainItem "+index);
    setItemIndex(index);
  };



  useEffect(() => {

    //console.log("SupplyChainPage useEffect");


    var the_positions = [];

    if(data && data.products && data.batch && data.products.length > 0){


      var items = products_to_supply_chain_data(data, defaultProductImage, config);

      const storedData = localStorage.getItem("storedItems");

      try {
          let storedItems = storedData ? JSON.parse(storedData) : null;

          console.log("stored items");
          console.log(storedItems);

          if (storedItems) {
              const storedIds = new Set(storedItems.map(item => item.item_id));
              const allPresent = items.every(item => storedIds.has(item.item_id));

              if (!allPresent) {
                  // Update storage only if IDs are different
                  localStorage.setItem("storedItems", JSON.stringify(items));
                  console.log("stored items updated");

              } else {
                  // Keep existing stored items
                  console.log("stored items copied to current");
                  items = storedItems;
              }
          } else {
              // Store items if nothing was there before
              console.log("stored items updated first time");
              localStorage.setItem("storedItems", JSON.stringify(items));
          }
      } catch (e) {
          console.error("SupplyChain Cache: Error parsing stored items:", e);
          localStorage.setItem("storedItems", JSON.stringify(items));
      }


      setSupplyChainItems(items);

      items.forEach((it)=>{
        the_positions.push(it.coords);
      })

      setMapPositions(the_positions);

      console.log(items);
    }
  }, [data, config.DOMAIN]);




  useEffect(() => {
      displaySupplyChainItem(itemIndex);
      selectSupplyChainItem(itemIndex);

  }, [itemIndex]);


  useEffect(() => {
      displaySupplyChainItem(0)
  }, [displaySupplyChainItem]);


  const globals = document.getElementById('globals');
  var is_there_supplychain_dom = globals.getAttribute('is_there_supplychain');
  var is_there_supplychain = is_there_supplychain_dom==='true';

  if(!data){
    return(
      <PageContainer>
        <div className="page-body page-body-loader">
          <Loader metadata={metadata} />
          <FooterNavigator qr_code_hash={qr_code_hash} selected_page={4} data={data} is_there_supplychain={is_there_supplychain} />
        </div>
      </PageContainer>
    )
  }



  return (
      <PageContainer>
        <TitleHeader title={t('filiera')} />

        <div className="page-body page-body-noheader">

          { supplyChainItems.length===0 && (

            <Loader  metadata={metadata}  />

          )}


          { supplyChainItems.length>0 && (


              <div>
                  <div>
                    <SupplyChainScroller items={supplyChainItems} callback={selectSupplyChainItem} index={itemIndex} title={""} />
                  </div>
                  <h2>{productName}</h2>

                  <div className="batch-box-container">

                    {

                      <BatchList batches={productLots} selectedProduct={itemIndex}/>
                      /*productLots.map((pl,index)=>(
                        <BatchBox claim="" batch={pl} clickable={true} />
                      ))*/
                    }
                  </div>


                  {mapPositions && mapPositions.length>0 && (
                    <div>
                      <h2>{t('origine')}</h2>
                      <div className="map-outer">
                        <MapRoute points={mapPositions} selected_index={itemIndex} zoomControl={true} />
                      </div>
                    </div>
                  )}

                  <h2>{t('descrizione')}</h2>
                  <p className="gtrans">
                    {productDescription}
                  </p>


            </div>

         )}


          <FooterNavigator qr_code_hash={qr_code_hash} selected_page={4} data={data}  is_there_supplychain={is_there_supplychain} />
        </div>
      </PageContainer>
  );
};

export default SupplyChainPage;
