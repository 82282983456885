import React from 'react';
import { smart_newlines, format_date } from '../tools/utils';
import imago from '../img/lotto.png';
import { useTranslation } from 'react-i18next';
import arrowUrl from '../img/arrow_right.png'; // Update the path to your image file


const BatchBox = ({ claim, batch, isSelected, onSelect }) => {

  const name = batch.foodchain_batch.internal_number;
  const date = format_date(batch.foodchain_batch.creation_date);
  const item_id = batch.foodchain_batch.item_id;
  const { t } = useTranslation();

  const handleBatchBoxOnClick = () => {
    window.location.href = "/"+(item_id.substring(2));
  }

  if (!batch || !batch.foodchain_batch) {
    return <div></div>;
  }

  return (
    <div
      onClick={onSelect}
      className={"batch-box-outer " + (isSelected ? "selected" : "")}
    >
      <div className="batch-box">
        <div className="batch-box-icon">
          <img src={imago} alt={claim} className="batch-box-image" />
        </div>
        <div className="batch-box-content">
          <div className="batch-box-type">
            <span>LOTTO</span> <div style={{ float: 'right' }}>{date}</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: smart_newlines(name, 35) }}
            className="batch-box-title"
          ></div>
        </div>
      </div>
      <div className={"batch-box-discovermore "+(isSelected?"active":"inactive")} onClick={handleBatchBoxOnClick}>
        <span>{t('discover_more')}&nbsp;&nbsp;&nbsp;</span>
         <img alt="arrow" style={{paddingTop:"3px"}} src={arrowUrl} />
      </div>
    </div>
  );
};

export default BatchBox;
